export enum Socials {
  xTwitter = "https://twitter.com/memgrizz",
  facebook = "https://www.facebook.com/MemphisGrizzlies",
  instagram = "https://www.instagram.com/memgrizz/",
  youtube = "https://www.youtube.com/memphisgrizzlies",
  tiktok = "https://www.tiktok.com/@memgrizz"
}

export enum teamLogos {
	ATL = "https://cdn.nba.com/logos/nba/1610612737/primary/L/logo.svg",
	BOS = "https://cdn.nba.com/logos/nba/1610612738/primary/L/logo.svg",
	CLE = "https://cdn.nba.com/logos/nba/1610612739/primary/L/logo.svg",
	NOP = "https://cdn.nba.com/logos/nba/1610612740/primary/L/logo.svg",
	CHI = "https://cdn.nba.com/logos/nba/1610612741/primary/L/logo.svg",
	DAL = "https://cdn.nba.com/logos/nba/1610612742/primary/L/logo.svg",
	DEN = "https://cdn.nba.com/logos/nba/1610612743/primary/L/logo.svg",
	GSW = "https://cdn.nba.com/logos/nba/1610612744/primary/L/logo.svg",
	HOU = "https://cdn.nba.com/logos/nba/1610612745/primary/L/logo.svg",
	LAC = "https://cdn.nba.com/logos/nba/1610612746/primary/L/logo.svg",
	LAL = "https://cdn.nba.com/logos/nba/1610612747/primary/L/logo.svg",
	MIA = "https://cdn.nba.com/logos/nba/1610612748/primary/L/logo.svg",
	MIL = "https://cdn.nba.com/logos/nba/1610612749/primary/L/logo.svg",
	MIN = "https://cdn.nba.com/logos/nba/1610612750/primary/L/logo.svg",
	BKN = "https://cdn.nba.com/logos/nba/1610612751/primary/L/logo.svg",
	NYK = "https://cdn.nba.com/logos/nba/1610612752/primary/L/logo.svg",
	ORL = "https://cdn.nba.com/logos/nba/1610612753/primary/L/logo.svg",
	IND = "https://cdn.nba.com/logos/nba/1610612754/primary/L/logo.svg",
	PHI = "https://cdn.nba.com/logos/nba/1610612755/primary/L/logo.svg",
	PHX = "https://cdn.nba.com/logos/nba/1610612756/primary/L/logo.svg",
	POR = "https://cdn.nba.com/logos/nba/1610612757/primary/L/logo.svg",
	SAC = "https://cdn.nba.com/logos/nba/1610612758/primary/L/logo.svg",
	SAS = "https://cdn.nba.com/logos/nba/1610612759/primary/L/logo.svg",
	OKC = "https://cdn.nba.com/logos/nba/1610612760/primary/L/logo.svg",
	TOR = "https://cdn.nba.com/logos/nba/1610612761/primary/L/logo.svg",
	UTA = "https://cdn.nba.com/logos/nba/1610612762/primary/L/logo.svg",
	MEM = "https://cdn.nba.com/logos/nba/1610612763/primary/L/logo.svg",
	WAS = "https://cdn.nba.com/logos/nba/1610612764/primary/L/logo.svg",
	DET = "https://cdn.nba.com/logos/nba/1610612765/primary/L/logo.svg",
	CHA = "https://cdn.nba.com/logos/nba/1610612766/primary/L/logo.svg"
}

export enum teamIDs {
	ATL = 1610612737,
	BOS = 1610612738,
	CLE = 1610612739,
	NOP = 1610612740,
	CHI = 1610612741,
	DAL = 1610612742,
	DEN = 1610612743,
	GSW = 1610612744,
	HOU = 1610612745,
	LAC = 1610612746,
	LAL = 1610612747,
	MIA = 1610612748,
	MIL = 1610612749,
	MIN = 1610612750,
	BKN = 1610612751,
	NYK = 1610612752,
	ORL = 1610612753,
	IND = 1610612754,
	PHI = 1610612755,
	PHX = 1610612756,
	POR = 1610612757,
	SAC = 1610612758,
	SAS = 1610612759,
	OKC = 1610612760,
	TOR = 1610612761,
	UTA = 1610612762,
	MEM = 1610612763,
	WAS = 1610612764,
	DET = 1610612765,
	CHA = 1610612766
}

export enum BackgroundTypes {
	black = "black.jpg",
	midnighteyes = "midnight-eyes.jpg",
	midnight = "midnight.jpg",
	throwback = "throwback.jpg",
	white = "white.jpg",
	gritty = "gritty.jpg",
	beale = "beale.jpg"
}

export enum BackgroundColor {
	black = "#000000",
	bealeStreetBlue = "#7d98c1",
	bealeStreetBlueHover = "#204275",
	memphisMidnight = "#0c2340",
	memphisMidnightHover = "#010b18",
	gold = "#ffc72c",
	goldActive = "#fdba00",
	gray = "#707372",
	greenFeatured = "rgb(90, 190, 190)"
}

export enum TicketsActionTypes {
	SET_TICKETS = "SET_TICKETS",
	SET_MONTH = "SET_MONTH",
	SET_DAY = "SET_DAY",
	SET_TEAM = "SET_TEAM",
	SET_PROMO = "SET_PROMO",
	SET_ERROR = "SET_ERROR",
	SET_LOADING = "SET_LOADING"
}

export enum CapiContentTypes {
	article = "article",
	gallery = "gallery",
	video = "video"
}

export enum NewsActionTypes {
	SET_POSTS = "SET_POSTS",
	SET_ERROR = "SET_ERROR",
	SET_LOADING = "SET_LOADING",
	SET_PAGE = "SET_PAGE",
	SET_TABLET = "SET_TABLET"
}

export enum CapiEndpointTypes {
	LAYOUT_NAV = "site/layout/nav",
	LAYOUT_FOOTER = "site/layout/footer",
	CONTENT = "content",
	ROSTER = "roster",
	LEADERS = "leaders",
	SITE_SETTINGS = "site/settings",
	SCHEDULE = "schedule"
}

export enum GroupTicketsContactFormFields {
	group_type = "group_type",
	fan_experience = "fan_experience",
	first_name = "first_name",
	last_name = "last_name",
	email = "email",
	phone = "phone",
	organization = "organization",
	tell_us_about_your_group = "tell_us_about_your_group"
}

export enum GroupTicketsContactFormStatus {
	not_submitted = "not_submitted",
	submitting = "submitting",
	submitted = "submitted",
	submission_failed = "submission_failed"
}

export enum GroupExperienceTypes {
	select = "- Select -",
	fantunnel = "Fan Tunnel",
	colorguard = "Color Guard",
	grizzgirlexperience = "Grizz Girl Experience",
	anthembuddies = "Anthem Buddies",
	pregameshootaround = "Pregame Shootaround",
	primetimeperformance = "Primetime Performance",
	nationalanthem = "National Anthem",
	postgamephoto = "Postgame Photo",
	postgamefreethrows = "Postgame Free Throws",
	playlikethepros = "Play Like The Pros"
}

export enum GroupExperienceActionTypes {
	SET_IS_MOBILE = "SET_IS_MOBILE",
	SET_ACCORDION_STATE = "SET_ACCORDION_STATE"
}

export enum FormstackFormStatus {
  not_submitted = "not_submitted",
  submitting = "submitting",
  submitted = "submitted",
  submission_failed = "submission_failed",
  loading = "loading",
  deactivated = "deactivated"
}

export enum EHomeKey {
  h = "h",
  v = "v",
}

export enum EPlayerStatKeys {
	assists = "assists",
	points = "points",
	rebounds = "rebounds"
}

export enum EScheduleActionTypes {
	"show_upcoming_or_past"="show_upcoming_or_past",
	"set_year"="set_year",
	"save_week_days"="save_week_days",
	"set_filtered_schedule_data"="set_filtered_schedule_data",
	"selected_team"="selected_team",
	"set_home_or_away_games"="set_home_or_away_games",
	"set_seasontype"="set_seasontype",
	"set_active_video"="set_active_video",
	"reset_filters"="reset_filters",
	"set_google_spreadsheet_data"="set_google_spreadsheet_data",
	"save_months"="save_months",
	"set_date_range"="set_date_range",
	"set_weekends"="set_weekends",
	"set_team_list"="set_team_list",
	"set_offers"="set_offers",
	"set_layout_type"="set_layout_type"
}

export enum EHomeOrVersus {
	h = "h",
	v = "v",
  }