import { NextPage } from "next";
import Head from "next/head";
import { CSSProperties, useEffect } from "react";
import { baseURL, s3BaseURL, siteDescription, siteName } from "../../../../etc/settings";
import { IPageWrapper, Socials } from "../../../../etc/types";
import smoothscroll from "smoothscroll-polyfill"

import styles from "../assets/css/index.module.scss";
import TagManager from "../../../../etc/modules/tag-manager/tag-manager";

const BlankPageWrapper: NextPage<IPageWrapper> = (props) => {
	const {
		title,
		description,
		pageType,
		pageImage,
		pageLink,
		xTwitterHandle,
		backgroundImage,
		backgroundColor,
		styles: extraStyles,
	} = props;


	useEffect(() => {
		TagManager.initialize({
			gtmId: "GTM-M7K5VCB",
		})
	}, [])

	useEffect(() => smoothscroll.polyfill(), [])

	let pageContentStyles: CSSProperties | undefined = {};

	// Removes legacy/depricated confetti background but still allows background images 10.11.24
	if (backgroundImage) {
		pageContentStyles.backgroundImage = backgroundImage.match(
			/(http|https)([^>]*[^/].(?:jpg|bmp|gif|png|webp|svg))/gi
		)
			? `url(${backgroundImage})`
			: ""
	}

	if (backgroundColor) {
		pageContentStyles.backgroundColor = backgroundColor;
	}

	if (extraStyles) {
		pageContentStyles = { ...pageContentStyles, ...extraStyles };
	}

	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta charSet="utf-8" />

				<title>{`${title} | ${siteName}`}</title>
				<meta name="description" content={description} />
				<link rel="canonical" href={pageLink} />
				<link
					rel="icon"
					type="image/png"
					href={s3BaseURL + 'assets/img/MG_icon_white.svg'}
				/>

				{/* Open Graph */}
				<meta property="og:type" content={pageType ? pageType : "website"} />
				<meta property="og:locale" content="en_US" />
				<meta property="og:url" content={pageLink} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta property="og:image:type" content="image/jpeg" />
				<meta property="og:image" content={pageImage} />
				<meta property="og:image:url" content={pageImage} />
				<meta property="og:image:secure_url" content={pageImage} />

				{/* xTwitter Card */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content={xTwitterHandle ? xTwitterHandle : "@memgrizz"} />
				<meta name="twitter:creator" content={xTwitterHandle ? xTwitterHandle : "@memgrizz"} />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				<meta name="twitter:url" content={pageLink} />
				<meta name="twitter:image" content={pageImage} />

				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "http://schema.org",
						"@type": "SportsOrganization",
						"@id": baseURL + "#memphis-grizzlies-organization",
						name: siteName,
						url: baseURL,
						description: siteDescription,
						logo: "https://cdn.nba.com/logos/nba/1610612763/primary/L/logo.svg",
						brand: {
							"@type": "Organization",
							name: siteName,
						},
						sport: "Basketball",
						sameAs: [
							Socials.facebook,
							Socials.xTwitter,
							Socials.instagram,
							Socials.youtube,
							Socials.tiktok,
							"https://en.wikipedia.org/wiki/Memphis_Grizzlies",
						],
					})}
				</script>

				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "http://schema.org",
						"@type": "WebPage",
						"@id": baseURL + "#webpage",
						url: baseURL,
						name: siteName,
					})}
				</script>
			</Head>

			<div className={styles.pageContent}>
				<div className="page-bg" style={pageContentStyles} />
				{props.children}
			</div>
		</>
	);
};

export default BlankPageWrapper;
