import Snippets from "./snippets"
import { IGTMConfigs } from "./types"

const TagManager = {
    dataScript: function (dataLayer:string) {
        const script = document.createElement('script')
        script.innerHTML = dataLayer
        return script
    },
    gtm: function (args: IGTMConfigs) {
        const snippets = Snippets.tags(args)

        const noScript = () => {
            const noscript = document.createElement('noscript')
            noscript.innerHTML = snippets.iframe
            return noscript
        }

        const script = () => {
            const script = document.createElement('script')
            script.innerHTML = snippets.script
            return script
        }

        const dataScript = this.dataScript(snippets.dataLayerVar)

        return {
            noScript,
            script,
            dataScript
        }
    },
    initialize: function ({ gtmId, events = {}, dataLayer, dataLayerName = 'dataLayer', auth = '', preview = '', scriptLoadingType = 'async' }: IGTMConfigs) {
        const gtm = this.gtm({
            gtmId: gtmId,
            events: events,
            dataLayer: dataLayer || undefined,
            dataLayerName: dataLayerName,
            auth,
            preview,
            scriptLoadingType,
        })
        
        if (dataLayer) {
            document.head.appendChild(gtm.dataScript)
        }

        document.head.insertBefore(gtm.script(), document.head.childNodes[0])
        document.body.insertBefore(gtm.noScript(), document.body.childNodes[0])
    },
    dataLayer: function ({ dataLayer, dataLayerName = 'dataLayer' }: {
        dataLayer: object,
        dataLayerName: string | undefined
    }) {
        if (window[dataLayerName as keyof Window]) {
            return window[dataLayerName as keyof Window].push(dataLayer)
        }

        const snippets = Snippets.dataLayer(dataLayer, dataLayerName)
        const dataScript = this.dataScript(snippets)

        document.head.insertBefore(dataScript, document.head.childNodes[0])
    }
}

export default TagManager