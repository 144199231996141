import React, { Dispatch, ReactNode, SetStateAction } from "react"
import {
	BackgroundColor,
	BackgroundTypes,
	CapiContentTypes,
	FormstackFormStatus,
	GroupExperienceActionTypes,
	GroupExperienceTypes,
	GroupTicketsContactFormFields,
	NewsActionTypes,
	TicketsActionTypes
} from "./enums"
import {
	DateTime,
	GroupTicketsActions,
	FormstackFormFieldTypes,
	Prices,
	Teams,
	TixType,
	THomeOrAwayOptions,
	TSeasonTypes,
	TlayoutType
} from "./types"

export interface IHeader {
	title: string
	description: string
	pageType?: string
	pageImage: string
	pageLink: string
	xTwitterHandle?: string
}

export interface IPageWrapper extends IHeader {
	backgroundImage?: BackgroundTypes | string
	backgroundColor?: BackgroundColor | string
	styles?: Object
	children?: React.ReactNode
}

export interface IMinNavigation {
	variant: "midnight" | "beale" | "transparent-white" | "transparent-black"
	logo?: string
	className?: string
}

export interface IActionButton {
	title: string
	href: string
	target?: string
	rel?: string
	extraClasses?: string
}

export interface IFeaturedImage {
	title: string
	description?: string
	src: string
	alt?: string
	heightRatio?: number
	actionButton?: IActionButton
	children?: React.ReactNode
}

export interface IMediaCard {
	mediaType: "image" | "video" | "youtube"
	src: string
	title: string
	backdrop?: "midnight" | "gold" | "beale" | "gray" | "black" | "white"
	bg?: "midnight" | "gold" | "beale" | "gray" | "black" | "white"
	text?: "midnight" | "gold" | "beale" | "gray" | "black" | "white"
	aspectRatio?: "1x1" | "4x3" | "16x9" | "21x9"
	link?: string
	target?: "_self" | "_blank"
	headline?: string
	poster?: string
	className?: string
}

export interface IMediaCardView {
	mediaType: "image" | "video" | "youtube"
	src: string
	title: string
	poster?: string
}

export interface IBusinessCards {
	headerText?: string
	introText?: string
	children?: React.ReactNode
}

export interface IBusinessCardItem {
	fullName: string
	title: string
	department: string
	imageLink: string
	linkedinLink?: string
}

export interface IPartnerList {
	title: string
	link: string
}

export interface IImageLeftTextRight {
	imageWidthPercent?: number
	src: string
	alt: string
	href: string
	target?: string
	headerText?: string
	copyText: string
	actionButton?: IActionButton
	children?: React.ReactNode
}

export interface ITextLeftImageRight extends IImageLeftTextRight {}

export interface ICenteredImageLeftTextRight extends IImageLeftTextRight {}

export interface ICenteredTextLeftImageRight extends IImageLeftTextRight {}

export interface ITicketsProps {
	spreadsheetId: string
	apiKey: string
	promoFilterChecked?: boolean
	ticketType?: string
	ticketQueryString?: string
	showTicketsAfterdate?: string
	showGroupPricingFilter?: string
	isncaa?: string
	tuneInLink?: string
}

export interface IMonthFilter {
	months: DateTime[]
	selectedMonth: DateTime
	callback: (month: DateTime) => void
}

export interface ITeamFilter {
	teams: Teams[]
	selectedTeam: Teams
	callback: (team: Teams) => void
}
export interface IPromoFilter {
	selectedPromo: boolean
	callback: (selectedPromo: boolean) => void
}

export interface ITixEntity {
	entity: TixType
	params: ITicketsProps
}

export interface IFeaturedImageViewInlineStyles {
	backgroundImage: string
	paddingBottom?: string
}

export interface IDayFilter {
	days: DateTime[]
	selectedDay: DateTime
	callback: (month: DateTime) => void
}

export interface IGroupPriceFilter {
	groupPrices: Prices[]
	selectedGroupPrice: Prices
	callback: (groupPrice: Prices) => void
}

export interface IBlockquote {
	quote: string
	author: string
}

export interface ICard {
	src: string
	alt: string
	headerText?: string
	copyText: string
	actionButton?: IActionButton
	children?: React.ReactNode
}

export interface ICardCentered extends ICard {}
export interface ICardCenteredNew extends ICard {
	actionButton: IActionButton
}

export interface HealthNFitness extends ICardCenteredNew {}

export interface ITicketsState {
	tickets: TixType[]
	selectedMonth: DateTime
	selectedDay: DateTime
	selectedTeam: Teams
	selectedPromo: boolean
	error: boolean
	monthDefaults: DateTime[]
	dayDefaults: DateTime[]
	selectedTeamDefaults: Teams[]
	loading: boolean
}

export interface ISetTicketAction {
	type: TicketsActionTypes.SET_TICKETS

	payload: {
		tickets: TixType[]
		loading: boolean
	}
}

export interface ISetMonthAction {
	type: TicketsActionTypes.SET_MONTH
	value: DateTime
}

export interface ISetDayAction {
	type: TicketsActionTypes.SET_DAY
	value: DateTime
}

export interface ISetTeamAction {
	type: TicketsActionTypes.SET_TEAM
	value: Teams
}

export interface ISetPromoAction {
	type: TicketsActionTypes.SET_PROMO
	value: boolean
}

export interface ISetErrorAction {
	type: TicketsActionTypes.SET_ERROR
	value: boolean
}

export interface ISetLoadingAction {
	type: TicketsActionTypes.SET_LOADING
	value: boolean
}

export interface ICapiResponse {
	results: {
		count: number
		pages: number
		pageNext: number
		total: number
		items: IPost[]
	}
}

export interface IPost {
	author: {
		authorPhoto: string
		description: string
		firstName: string
		lastName: string
		name: string
		slug: string
		socialHandle: string
		socialLink: string
	}
	category: string
	categoryPrimary: {
		name: string
		slug: string
	}
	contentStructured: {
		attributes: {
			level: number
			src: string
		}
		html: string | undefined
		type: "list" | "heading" | "image" | "paragraph"
	}[]
	date: string
	excerpt: string
	featuredImage: {
		alt: string
		caption: string
		credit: string
		size: {
			height: number
			width: number
		}
		src: string
	}
	id: number
	name: string
	permalink: string
	slug: string
	taxonomy: {
		categories: {
			[key: string]: string
		}
		tags: {
			[key: string]: string
		}
	}
	title: string
	type: CapiContentTypes
}

export interface INewsState {
	loading: boolean
	page: number
	posts: IPost[]
	total: number
	error: boolean
	callback?: () => void
	isTablet: boolean
}

// Please refer to DPDPS-EndpointReference-300322-1435.pdf document for all available properties and
// possible ways to query content
export interface CapiSearchParams {
	//Comma-separated string of content types. Example: ?types=article,video,page
	types?: CapiContentTypes[] | string

	//Maximum number of results to return; default 12. Example: ?count=10
	count?: number

	//The page from which to begin returning results; default 1. Example: ?page=2
	page?: number

	//The offset from which to start pagination; default 0. Example: ?offset=2
	offset?: number

	//Meta query to match on. Takes the format meta-{key}={value}. Example: ?meta-test=true&meta-test2=false
	meta?: string

	//Meta query to not match on. Takes the format exclude-meta-{key}={value}. Example: ?exclude-meta- test=yes
	excludeMeta?: string

	//Term query to match on. Takes the format term-{key}={value}. Example: ?term-category=test
	"term-post_tag"?: string

	//Term query to match on. Takes the format term-{key}={value}. Example: ?term-category=test
	term?: string

	//Comma-separated string of content ID’s to exclude. Example: ?exclude=12,14
	exclude?: number

	//A list of team IDs to query on. Example: ?teams=1610612741
	teams?: number

	//A list of game IDs to query on. Example: ?games=010221|021621
	games?: string

	//A list of player IDs to query on. Example: ?players=123+456
	players?: string

	//Timestamp to get content before. Example: ?before=1630439526
	before?: number

	//Timestamp to get content after. Example: ?after=1630439526
	after?: number

	//Freeform search term. Example: ?q=Find this text
	q?: string

	//Type of platform requesting content. Example: ?platform=web
	platform?: string

	//Author ID to query by. Example: ?author=12
	author?: number

	//Author name to query by. Example ?authorName=Bob Ross
	authorName?: string

	//How results should be sorted; default post date descending. Example: sort=rel
	sort?: string

	//Only return events that start on this given day. Example: ?eventDate=2020-06-21
	eventDate?: string

	//The region target. Example: ?region=domestic
	region?: string

	//Return an accurate total when the total exceeds 10,000; default false. Example: ?accurateTotal=true
	accurateTotal?: boolean

	//Permalink to query for. Example: ?permalink=https://www.nba.com/bulls
	permalink?: string

	//A comma-separated string of post statuses to filter by. Example: ?status=draft,publish
	status?: string

	//These two is to be able to fetch content by Tag https://content-api-prod.nba.com/public/1/leagues/nba/teams/1610612754/content/index/article?type=tag&slug=community
	type?: string
	slug?: string
}

export interface ISetNewsAction {
	type: NewsActionTypes.SET_POSTS
	payload: {
		posts: IPost[]
		loading: boolean
		total: number
	}
}

export interface ISetNewsErrorAction {
	type: NewsActionTypes.SET_ERROR
	value: boolean
}

export interface ISetNewsLoadingAction {
	type: NewsActionTypes.SET_LOADING
	value: boolean
}

export interface ISetNewsPageAction {
	type: NewsActionTypes.SET_PAGE
	value: number
}

export interface ISetNewsTabletAction {
	type: NewsActionTypes.SET_TABLET
	value: boolean
}

export interface IArticleItemView {
	post: IPost
	isTablet?: boolean
}

export interface IArticleItemLeadingView extends IArticleItemView {}

export interface IVoteCarouselItem {
	date: string
	img: string
}

export interface IVoteView {
	isMobile: boolean
	NewsBlock: React.FC
}

export interface IGrizzVotesPhases {
	tagline: string
	dateStart: string
	dateEnd: string
	backendDateEnd: string
	primaryCTACopy: string
	primaryCTAButtonLink: string
	secondaryCTACopy: string
	secondaryCTALink: string
}

export interface IBenefit {
	image: string
	title: string
	description: string[]
	extraClasses?: string
}

export interface XPList extends IBenefit {
	xpType: GroupExperienceTypes
}

export interface IFaq {
	groupName: string
	question: string
	answer: string[]
}

export interface IGroupTicketsView {
	faqGroup: IFaq[][]
	callback: (e: IFaq) => void
	children?: React.ReactNode
}

export interface IGroupSalesTeam {
	name: string
	title: string
	focus?: string
	phone?: string
	email?: string
	photo: string
}
export interface StaffCard extends IGroupSalesTeam {
	assetsBaseURL?: string
}

export interface StaffCardBlock {
	title: string
	items: StaffCard[]
	assetsBaseURL: string
}

export interface IGroupTicketsConnectForm {
	group_type: string
	fan_experience: string
	first_name: string
	last_name: string
	email: string
	phone: string
	organization: string
	tell_us_about_your_group: string
}

export interface IGroupTicketsSubmissionFormView {
	state: IGroupTicketsConnectForm
	errors: string[]
	changeHandler: (key: GroupTicketsContactFormFields, value: string) => void
	submitHandler: (e: React.FormEvent<HTMLFormElement>) => void
}

export interface IGroupExperienceTypes {
	fantunnel: string
	colorguard: string
	grizzgirlexperience: string
	anthembuddies: string
	pregameshootaround: string
	primetimeperformance: string
	nationalanthem: string
	postgamephoto: string
	postgamefreethrows: string
	playlikethepros: string
}

export interface ISetGroupExperienceIsMobileAction {
	type: GroupExperienceActionTypes.SET_IS_MOBILE
	value: boolean
}

export interface ISetGroupExperienceAccordionState {
	type: GroupExperienceActionTypes.SET_ACCORDION_STATE
	value: string[]
}

export interface GroupTicketsState {
	isMobile: boolean
	acState: string[]
	dispatch: Dispatch<GroupTicketsActions>
}

export interface IYouTubeVideo {
	title: string
	description: string
	videoId: string
}

export interface IFormstackFormSelectOption {
	label: string
	value: string
	imageUrl: string
}

export interface IFormstackFormField {
	id: string
	cols: number
	colspan: string
	date_format?: string
	default?: string
	label: string
	hidden: string
	hide_label: string
	description: string
	name: string
	options: IFormstackFormSelectOption[] | string
	required: string
	rows: number
	section_text?: string
	type: FormstackFormFieldTypes
	visible_subfields?: string[]
}
export interface IFormstackFormDetails {
	colspan: string
	id: string
	inactive: boolean
	fields: IFormstackFormField[]
	name: string
	num_columns: string
	sort: string
	submit_button_title: string
	children?: React.ReactNode
}

export interface IFormstackFormDetailsWithUserData extends IFormstackFormDetails {
	user_agent: string
	remote_addr: string
	latitude: string
	longitude: string
}

export interface IFormstackFormDetailsAPIResponse {
	statusCode: number
	body: IFormstackFormDetails
	aws_headers: {
		["CloudFront-Viewer-Address"]: string
		["User-Agent"]: string
		["CloudFront-Viewer-Latitude"]: string
		["CloudFront-Viewer-Longitude"]: string
	}
}

export interface IFormStackAPIAPIStatusCodes {
	[idx: number]: string
}

export interface IFormstackExtraOptions {
	// If false, each form field will take the full width of its parent container
	colspan?: boolean
	initialState?: FormstackFormStatus
	// Any callback function you would want to execute after form submission happened (does not matter successful or failure)
	finalCallback?: () => void
	noRichText?: boolean
	// Provide your own markup
	// https://reactjs.org/docs/introducing-jsx.html
	formDeactivatedMessage?: JSX.Element
	// Provide your own markup
	// https://reactjs.org/docs/introducing-jsx.html
	submittingMessage?: JSX.Element
	// Provide your own markup
	// https://reactjs.org/docs/introducing-jsx.html
	submittedMessage?: JSX.Element
	// Provide your own markup
	// https://reactjs.org/docs/introducing-jsx.html
	submissionFailedMessage?: JSX.Element

	// Here's the example of your configuration object you would put in your view
	// with all available customizations
	// const formstackParams = {
	//  formID: 4899548,
	//  options: {
	//   colspan: true,
	//   initialState: FormstackFormStatus.submission_failed,
	//   submittingMessage: <div>Custom Submitting HTML</div>,
	//   submittedMessage: <div>Custom Submitted HTML</div>,
	//   submissionFailedMessage: <div>Custom Failed sumission HTML</div>,
	//   formDeactivatedMessage : <div>This form has been deactivated</div>
	// }
	// }
}

export interface IFormStackParams {
	formID: number
	options?: IFormstackExtraOptions
	children?: React.ReactNode
}

export interface IFormStackFormView {
	formDetails: IFormstackFormDetails
	formState: IFormStackFormState
	callback: (field: IFormstackFormField, value: any) => void
	submitHandler: (e?: React.FormEvent<HTMLFormElement>) => void
	options?: IFormstackExtraOptions
	children?: React.ReactNode
}

export interface IFormStackFormState {
	errors: string[]
	formStatus: FormstackFormStatus
	values: {}
}

export interface IStatementEditionUniformItem {
	link: string
	description: string
}

export interface IStatementEditionView {
	isMobile: boolean
	show: boolean
	callback: (a: IStatementEditionUniformItem) => void
	toggleModal: () => void
	uniformItem: IStatementEditionUniformItem
}

export interface IPartnershipListItem {
	icon: string
	title: string
	meta: string[]
	link?: string
	children?: React.ReactNode
}

export interface IYouthEventItem {
	title: string
	year: number
	date: string
	time?: string
	locationLink: string
	location: string
	ctaElement?: IActionButton
	children?: React.ReactNode
}

export interface HTMLInputEvent extends Event {
	target: HTMLInputElement & EventTarget
}

export interface IWatchProviders {
	title: string
	frequency: string
	notAvailableIn?: string[]
}

export interface IRegionalChannels {
	cities: string[]
	providers: IWatchProviders[]
}

export interface IBroadcastStaff {
	fullName: string
	profileLink: string
	title: string
}

export interface IGrizzYouthSocialIcons {
	link: string
	title: string
	icon?: React.ReactNode
}

export interface IGrizzShopSponsors {
	imgsrc: string
	alt: string
}

export interface IGrizzShopVendors {
	title: string
	logotype: string
	link: string
}

export interface IGrizzmasDeal {
	date: string
	title: string
	description: string
	file_name: string
	link: string
}

export interface IMLKPanel {
	image: string
	head?: string
	subhead: string
}

export interface I191ArtistProfile {
	id: string
	name: string
	title: string
	description: string
	logo: string
	collection_logo?: string
	collection_images?: string[]
	profile_link: string

	datetime: string
	opponent: {
		city: string
		name: string
		logo: string
	}
	vs_at: string
	tixlink: string

	bios: I191Bio[]
}

export interface I191Bio {
	name: string
	title: string
	description: string[]
	weblink?: string
	twitter?: string
	insta?: string
	facebook?: string
	linkedin?: string
	youtube?: string
	tiktok?: string
	img: string
}

export interface IfeaturedGames {
	id: string
	game: string
	sponsor: string
	date: string
	vs: string
	opp: string
	time: string
	buyTixLink: string
	parkingTixLink: string
	knowbeforeyougo: string
}

export interface IGrizzWeekEventsPlayoffs {
	month: string
	day: number
	title: string
	sponsor: string
	description: string
	secondBlockTitle: string
	secondBlockDescription: string
}

export interface PlayoffWatchParty {
	game: string
	weekday: string
	month: string
	day: number
	memhome: boolean
	mem: string
	memsponsor: string
	memlocation: string
	memlocationlink: string
	meminfo: string
	nash: string
	nashVisible: boolean
	nashsponsor: string
	nashlocation: string
	nashlocationlink: string
	nashinfo: string
}

export interface ICAPISchedule {
	type: string
	gid: string
	seasonYear: string
	gameStatusText: string
	gameSubtype: string
	seriesText: string
	easternTime: string
	visitorTime: string
	homeTime: string
	utcDateOnly: string
	utcTimeOnly: string
	utcTime: string
	arenaName: string
	arenaState: string
	arenaCity: string
	providers: ICAPIBroadcaster[]
	state: number
	visitor: ICAPITeam
	home: ICAPITeam
	title: string
	promotion: {}
	ctas: []
	tickets: {}
	courtsideBox: null
}
export interface ICAPIBroadcaster {
	broadcasterScope: string
	broadcasterMedia: string
	broadcasterId: number
	broadcasterDisplay: string
	broadcasterAbbreviation: string
	tapeDelayComments: string
	broadcasterVideoLink: string
	broadcasterTeamId: number
}
export interface ICAPITeam {
	tid: number
	ta: string
	tn: string
	tc: string
	ts: string
	wins: number
	losses: number
	score: number
	uniform: {
		name: string
		backgroundColor: string
		textColor: string
		lockervisionUrl: string
		editionImgUrl: string
	}
	leaders: ILeaders
}

export interface ILeaders {
	points: ILeaderStats
	rebounds: ILeaderStats
	assists: ILeaderStats
}

export interface ILeaderStats {
	playerId: number
	firstName: string
	lastName: string
	nameI: string
	stat: number
}

export interface ISCheduleDataRes {
	gid: string
	gdte: string
	etm: string
	gameSubtype: string
	an: string
	ac: string
	as: string
	st: string
	stt: string
	h: ITeam
	v: ITeam
	bd: {
		b: IBroadcaster[]
	}
	seasonType: string
	seasonYear: string
}

export interface ITeam {
	tid: number
	re: string
	ta: string
	tn: string
	tc: string
	s: string
	istGroup: string
	leaders: ILeaders
}

interface IBroadcaster {
	broadcasterId: number
	disp: string
	scope: string
	type: string
}

export interface IDateTimeColumn {
	e: ISCheduleDataRes
}

export interface IFutureGame {
	e: ISCheduleDataRes
	//metaData: ItransformSpreadsheetData
}

export interface IPastGame {
	e: ISCheduleDataRes
	fetchDelay: number
}

export interface IGoogleAPIRes {
	values: ItransformSpreadsheetData[]
}

export interface ItransformSpreadsheetData {
	date: string
	time: string
	opponent: string
	city: string
	team: string
	ticket: string
	giveaways: string
	themenight: string
	sponsorLogo: string
	uniform: ITransformedElement
}

export interface ITransformedElement {
	title: string
	link: string
}

export interface IInitalFormFiltersState {
	weekDaysChecked: Map<any, any>
	selectedTeam: string
	homeOrAwayGame: THomeOrAwayOptions
	seasonTypes: TSeasonTypes
	activeVideo: number
	firstGameId: number
	monthsChecked: Map<any, any>
	teamList: string[]
	offersChecked: Map<any, any>
	layoutType: TlayoutType
}

export interface IJuniorGrizzliesCampaignRaw {
	title: string
	description: string
	events: string[]
	url: string
}

export interface IJuniorGrizzliesCampaign {
	title: string
	description: string
	events: string[][]
	url: string
	children?: React.ReactNode
}

export interface IPhotoModal {
	children?: ReactNode
	imageSrc: string
	onHide: () => void
	show: boolean
}


interface Broadcaster {
    broadcasterScope: string;
    broadcasterMedia: string;
    broadcasterId: number;
    broadcasterDisplay: string;
    broadcasterAbbreviation: string;
    tapeDelayComments: string;
    broadcasterVideoLink: string;
    broadcasterDescription: string;
    broadcasterTeamId: number;
}

interface TeamLeaders {
    playerId: number;
    firstName: string;
    lastName: string;
    nameI: string;
    stat: number;
}

interface Team {
    tid: number;
    ta: string;
    tn: string;
    tc: string;
    ts: string;
    wins: number;
    losses: number;
    score: number;
    uniform: {
        name: string;
        backgroundColor: string;
        textColor: string;
        lockervisionUrl: string;
        editionImgUrl: string;
    };
    leaders: {
        points: TeamLeaders;
        rebounds: TeamLeaders;
        assists: TeamLeaders;
    };
}

interface Tickets {
    schedule: string;
    singleTickets: string;
    singleTicketsSecondary: string;
    gameBlock: string;
    courtsideBox: string;
    calendarUrl: string;
}

interface Promotion {
    media: null | string;
}

interface CourtsideBox {
    preGameSettings: any[];
    liveGameSettings: any[];
    postGameSettings: any[];
    courtsideSponsors: any[];
}

interface TravelPromotions {
    flight: string;
    hotel: string;
    carRental: string;
}

export interface GameObject {
    type: string;
    gid: string;
    seasonYear: string;
    gameStatusText: string;
    gameSubtype: string;
    ifNecessary: string;
    seriesGameNumber: string;
    gameLabel: string;
    gameSubLabel: string;
    seriesText: string;
    easternTime: string;
    visitorTime: string;
    homeTime: string;
    utcDateOnly: string;
    utcTimeOnly: string;
    utcTime: string;
    arenaName: string;
    arenaState: string;
    arenaCity: string;
    providers: Broadcaster[];
    state: number;
    visitor: Team;
    home: Team;
    title: string;
    promotion: Promotion;
    ctas: any[];
    tickets: Tickets;
    courtsideBox: CourtsideBox;
    travelPromotions: TravelPromotions;
    gameTickets: string;
    contentCarousel: any[];
    contentCarouselOrder: any[];
}

export interface GameObjectExtended {
    type: string;
    gid: string;
    seasonYear: string;
    gameStatusText: string;
    gameSubtype: string;
    ifNecessary: string;
    seriesGameNumber: string;
    gameLabel: string;
    gameSubLabel: string;
    seriesText: string;
    easternTime: string;
    visitorTime: string;
    homeTime: string;
    utcDateOnly: string;
    utcTimeOnly: string;
    utcTime: string;
    arenaName: string;
    arenaState: string;
    arenaCity: string;
    providers: Broadcaster[];
    state: number;
    visitor: Team;
    home: Team;
    title: string;
    promotion: Promotion;
    ctas: any[];
    tickets: Tickets;
    courtsideBox: CourtsideBox | null;
    travelPromotions: TravelPromotions;
    gameTickets: string;
    contentCarousel: any[];
    contentCarouselOrder: any[];
    Ticket_Link_: string;
    Giveaways: string;

}