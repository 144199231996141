export const baseURL = 'https://nba.com/grizzlies/'
export const team = 'grizzlies'
export const siteName = 'Memphis Grizzlies'
export const siteDescription = "The Memphis Grizzlies are an American professional basketball team based in Memphis, Tennessee. The Grizzlies compete in the National Basketball Association as a member of the league's Western Conference Southwest Division. The Grizzlies play their home games at FedExForum. The team is owned by Robert Pera."
//export const s3BaseURL = 'https://www.nba.com/resources/static/team/v2/grizzlies/'
export const s3BaseURL = 'https://s3.grizzliesapp.com/'
export const grizzScheduleGoogleSheetId = '12r-C7OTDXUyP7Gm9NW-M56QyFnnrJgRXKa5j3q-jOGk'
export const grizzGoogleDocsReadOnlyKey = 'AIzaSyCFSrkNO61TWdRB6tO6TpH_fxa3D4Ldle4'
export const getSheetLink = (sheetId: string, apiKey: string): string => `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/prod?alt=json&key=${apiKey}`
export const grizzliesTeamId = 1610612763;
export const capiEndpointLink = `https://content-api-prod.nba.com/public/1/leagues/nba/teams/${grizzliesTeamId}/`;
export const imagePlaceholder = 'https://s3.grizzliesapp.com/assets/img/loadingarticle.jpg';
export const formstackAPIEndpointFormSubmission = 'https://is93pnpvj1.execute-api.us-east-1.amazonaws.com/Prod/fs';
//export const formstackAPIEndpointGetFormDetails = 'https://is93pnpvj1.execute-api.us-east-1.amazonaws.com/Prod/formdetails';
export const formstackAPIEndpointGetFormDetails = 'https://dku5qwqmpp8ru.cloudfront.net';